import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --basic-transparent: rgba(255, 255, 255, 0);
    --basic-white: #ffffff;
    --primary-green-100: #006a4e;
    --primary-green-dark: #004b33;
    --primary-green-70: #4d9783;
    --primary-green-90: #1a7960;
    --primary-green-80: #338871;
    --primary-green-40: #99c3b8;
    --primary-green-10: #e6f0ed;
    --primary-grey-grey: #6d6e70;
    --primary-grey-dark-grey: #333333;
  }

  html,
  body {
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
  };

  body {
    overflow: auto;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  };

  #root, #___gatsby, #gatsby-focus-wrapper {
    min-height: 100%;
  };

  a {
    color: #000;

    &:hover {
      color: #000;
    }
  }
`;
