import React from "react"
import { ThemeProvider } from '@/theme';
import MessengerCustomerChat from 'react-messenger-customer-chat';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      {element}
      <MessengerCustomerChat pageId="102279101597346" appId="538881487506065" />
    </ThemeProvider>
  )
}
