import { Interpolation } from 'styled-components'
import { path } from 'ramda'

export const headerHeight = 56;
export const footerHeight = 310;
export const mobileToolbarHeight = 55;

export const breakpoints = {
  tablet: 768,
  desktop: 1084,
};

export const zIndex = {
  messageBar: 1,
  hcpPopupCard: 10,
  navLeft: 10,
  hcpProfileFormCommandBar: 90,
  header: 100,
  googlePlaces: 10000000,
};

export const palette = {
  basicTransparent: 'rgba(255, 255, 255, 0)',
  basicWhite: '#ffffff',
  primaryGreen100: '#006a4e',
  primaryGreendark: '#004b33',
  primaryGreen70: '#4d9783',
  primaryGreen90: '#1a7960',
  primaryGreen80: '#338871',
  primaryGreen40: '#99c3b8',
  primaryGreen10: '#e6f0ed',
  primaryGrey: '#6d6e70',
  primaryGreyDark: '#333333',
};

type Palette = typeof palette;

export const getColor = (color: keyof Palette) =>
  path<Interpolation<Palette>>(['theme', 'palette', color]);

/**
 * Theme
 */
export const createThemeFactory = () => {
  return {
    palette,
    breakpoints,
    media: {
      mobileOnly: `screen and (max-width: ${breakpoints.tablet - 0.0001}px)`,
      tablet: `screen and (min-width: ${breakpoints.tablet}px)`,
      desktop: `screen and (min-width: ${breakpoints.desktop}px)`,
      desktop1: `screen and (min-width: ${breakpoints.desktop}px)`,
    },
    headerHeight,
    footerHeight,
    mobileToolbarHeight,
    containerMaxWidth: 1722,
    zIndex,
  };
};
