import React, { FC } from 'react'
import { ThemeProvider as StyledProvider } from 'styled-components'
import { MediaContextProvider } from '.';
import { GlobalStyle } from './GlobalStyles'
import { createThemeFactory } from './theme'

const theme = createThemeFactory();

export const ThemeProvider: FC = ({ children }) => {
  return (
    <MediaContextProvider>
      <StyledProvider theme={theme}>
        {children}
        <GlobalStyle />
      </StyledProvider>
    </MediaContextProvider>
  )
}
